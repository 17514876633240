///
/// @file global/_functions.scss
///
/// \brief Functions
///
/// Useful functions to help us calculate various values
/// (Credit to Zurb Foundation, http://foundation.zurb.com/docs)
///

// RANGES
// We use these functions to define ranges for various things, like media queries.
@function lower-bound($range) {
  @if length($range) <= 0 {
    @return 0;
  }
  @return nth($range, 1);
}

@function upper-bound($range) {
  @if length($range) < 2 {
    @return 999999999999;
  }
  @return nth($range, 2);
}

// https://github.com/Igosuki/compass-mixins/issues/34
@function compact($args...) {
  $first: nth($args, 1);
  $sep: comma;
  $list: ();
  @if length($args) == 1 and type_of($first) == 'list' {
    $args: $first;
    $sep: list-separator($args);
  }
  @for $index from 1 through length($args) {
    $arg: nth($args, $index);
    @if $arg {
      $list: append($list, $arg, $sep);
    }
  }
  @return $list;
}

// Line height function to generate unitless numbers vs the px values in the styleguide.
@function get-line-height($font-size, $line-height) {
  @return $line-height / $font-size;
}
