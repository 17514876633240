@import '../../../../scss/theme-bootstrap';

.cart-block {
  @include transition(opacity 0.2s ease-in-out);
  &-loading {
    * {
      cursor: wait;
    }
  }
  &__close {
    position: absolute;
    top: 0;
    #{$rdirection}: 0;
    cursor: pointer;
    padding: 0.25em;
  }
  &__header {
    @include pie-clearfix;
    border-bottom: solid 1px rgba(0, 0, 0, 0.35);
    font-family: $font--subheading-alt;
    padding: 15px 0;
    margin: 0 15px;
    @include breakpoint($landscape-up) {
      margin: 0 20px;
    }
    &__item-count {
      display: none;
    }
  }
  &__title {
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 22px;
    &__text {
      display: none;
      &:hover {
        text-decoration: underline;
      }
      &--empty {
        display: inline-block;
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
      &--nonempty {
        .cart-block--nonempty & {
          display: inline-block;
        }
      }
      &--added {
        .cart-block--added & {
          display: inline-block;
        }
      }
    }
  }
  &__title-link {
    &:hover {
      text-decoration: underline;
    }
  }
  &__item-count {
    color: $color-text-gray;
    font-size: 1.25em;
    display: none;
    .cart-block--singular &--singular {
      display: none;
    }
    .cart-block--plural &--plural {
      display: none;
    }
    .cart-block--empty & {
      display: none;
    }
    .cart-block--added & {
      display: none;
    }
  }
  &__products {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  &__items {
    padding: 15px;
    @include breakpoint($landscape-up) {
      padding: 15px 20px;
    }
    // In the "new" state, only show the items that were just added:
    &--new {
      display: none;
      .cart-block--added & {
        display: block;
      }
    }
    .cart-block--added &--all {
      display: none;
    }
    &__view-bag {
      display: block;
      padding: 1em 0 2em;
    }
  }
  &__footer {
    border-top: solid 1px rgba(0, 0, 0, 0.35);
    padding: 15px 0;
    margin: 0 15px;
    @include breakpoint($landscape-up) {
      margin: 0 20px;
    }
    &__summary {
      @include pie-clearfix;
      @include font--subheading-alt;
      display: none;
      margin: 0 0 1em;
      font-size: 18px;
      .cart-block--nonempty &,
      .cart-block--added & {
        display: block;
      }
    }
    &__item-count {
      float: $ldirection;
      // Intentionally supressing item count in footer, see ORNF-122
      display: none;
      .cart-block--plural & {
        display: none;
      }
    }
    &__subtotal {
      color: $color-black;
      display: block;
      &__label {
        float: $ldirection;
      }
      &__value {
        float: $rdirection;
      }
      .cart-block--added & {
        display: none;
      }
    }
    &__button {
      width: 100%;
      text-align: center;
      font-size: 20px;
      &--checkout {
        display: none;
        .cart-block--nonempty &,
        .cart-block--added & {
          display: block;
        }
      }
      &--continue-shopping {
        .cart-block--nonempty &,
        .cart-block--added & {
          display: none;
        }
      }
    }
    &__fineprint {
      @include font--subheading-alt;
      font-size: 18px;
      padding-top: 15px;
      text-align: center;
      line-height: get-line-height(18px, 22px);
      a {
        text-decoration: none;
      }
    }
  }
}
